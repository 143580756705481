import dayjs from 'dayjs';
import localforage from 'localforage';
import type { EnergyTypes } from '../../types';

export const useExploreStorage = () => {
  // Store
  const { getAreaId, getPeriod, getEnergyType } = storeToRefs(exploreStore());
  const { setAreaId, setPeriod, setEnergyType } = exploreStore();

  // Data
  const clientId = computed(() => globalStore().getSelectedClient);
  const siteId = computed(() => globalStore().getSelectedSite);

  // ==================== Forage ====================
  const getKeysFromForage = async () => {
    const areaForage = await localforage.getItem<number>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-area`);
    const energyTypeForage = await localforage.getItem<EnergyTypes>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-energy-type`);
    const periodForage: { start: Date | null; end: Date | null } = JSON.parse(
      await localforage.getItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-period`),
    );

    // Check if the area is in the forage
    if (areaForage) {
      const area = areaStore().getArea(areaForage);
      area ? setAreaId(areaForage) : setAreaId(areaStore().getAreas[0].id);
    } else {
      setAreaId(areaStore().getAreas[0].id);
    }

    // Check if the energy type is in the forage and check if the area got this energy type
    if (energyTypeForage) {
      const area = areaStore().getArea(getAreaId.value);
      if (!area?.meters.find((meter) => meter.meter_type.energy_type === energyTypeForage)) {
        setEnergyType(area?.meters[0].meter_type.energy_type);
      } else {
        setEnergyType(energyTypeForage);
      }
    } else {
      setEnergyType(areaStore().getArea(getAreaId.value).meters[0].meter_type.energy_type);
    }

    // Check if period is in the forage
    if (periodForage?.start && periodForage?.end) {
      setPeriod(periodForage);
    } else {
      setPeriod({
        start: dayjs().subtract(1, 'month').set('hour', 0).set('minute', 0).toDate(),
        end: dayjs().set('hour', 0).set('minute', 0).toDate(),
      });
    }
  };

  const setKeysInForage = async () => {
    await localforage.setItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-period`, JSON.stringify(getPeriod.value));
    await localforage.setItem<number>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-area`, getAreaId.value);
    await localforage.setItem<string>(`nrjx-${clientId.value}-${siteId.value[0]}-explore-energy-type`, getEnergyType.value);
  };

  // ==================== Filters ====================

  return {
    getKeysFromForage,
    setKeysInForage,
  };
};
